import { Chip, ChipProps, ThemeProvider, createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';

import { theme } from '../../theme';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    green: true;
    blue: true;
    yellow: true;
    orange: true;
    red: true;
    basic: true;
    warning: true;
    counter: true;
  }
}

interface IProps extends ChipProps {
  label?: string;
  labelPadding?: string;
  variant?: 'green' | 'blue' | 'yellow' | 'orange' | 'red' | 'basic' | 'warning' | 'counter' | 'maroon';
}

export const QBadge = ({ label, labelPadding, ...props }: IProps) => {
  const getBadgeTheme = (palette = theme.palette) =>
    createTheme({
      components: {
        MuiChip: {
          variants: [
            {
              props: { variant: 'maroon' },
              style: {
                color: 'white',
                backgroundColor: palette.base.maroon,
              },
            },
            {
              props: { variant: 'green' },
              style: {
                color: 'white',
                backgroundColor: palette.base.green,
              },
            },
            {
              props: { variant: 'blue' },
              style: {
                color: 'white',
                backgroundColor: palette.base.blue,
              },
            },
            {
              props: { variant: 'yellow' },
              style: {
                color: 'black',
                backgroundColor: palette.base.yellow,
              },
            },
            {
              props: { variant: 'orange' },
              style: {
                color: 'white',
                backgroundColor: palette.base.orange,
              },
            },
            {
              props: { variant: 'red' },
              style: {
                color: 'white',
                backgroundColor: palette.base.red,
              },
            },
            {
              props: { variant: 'basic' },
              style: {
                color: 'white',
                backgroundColor: palette.text.secondary,
              },
            },
            {
              props: { variant: 'warning' },
              style: {
                color: 'white',
                backgroundColor: palette.state.warning,
              },
            },
            {
              props: { variant: 'counter' },
              style: {
                color: 'white',
                backgroundColor: palette.text.secondary,
                position: 'absolute',
                right: '20%',
              },
            },
          ],
          styleOverrides: {
            root: {
              borderRadius: '12px',
              height: '20px',
              color: '#424242',
              textTransform: 'capitalize',
              textAlign: 'center',
              fontWeight: 400,
              fontSize: '12px',
            },
          },
        },
      },
    });
  return (
    <ThemeProvider theme={(outerTheme: any) => deepmerge(getBadgeTheme({ ...theme.palette, ...outerTheme.palette }), outerTheme)}>
      <Chip
        {...props}
        label={label}
        sx={{
          '& .MuiChip-label': {
            padding: labelPadding,
          },
        }}
      />
    </ThemeProvider>
  );
};
